import { lazy, useMemo } from 'react';
import { BlockGamesProps } from './interface';

const Components = {
  GrabberBuilder: lazy(() => import('@/games/Grabber/builder')),
  Scratcher: lazy(() => import('@/games/Scratcher/builder')),
  ScratchAndMatch: lazy(() => import('@/games/ScratchAndMatch/builder')),
  SimplyScratcher: lazy(() => import('@/games/SimplyScratcher/builder')),
  SlotMachineBuilder: lazy(() => import('@/games/SlotMachine/builder')),
  ScratchCard3Builder: lazy(() => import('@/games/ScratchCard3/builder')),
  MysteryEnvelopeBuilder: lazy(() => import('@/games/MysteryEnvelope/builder')),
  OpenTheEnvelopeBuilder: lazy(() => import('@/games/OpenTheEnvelope/builder')),
  MysteryBoxBuilder: lazy(() => import('@/games/MysteryBox/builder')),
  PopTheBalloonBuilder: lazy(() => import('@/games/PopTheBalloon/builder')),
  SimplyPopBuilder: lazy(() => import('@/games/SimplyPop/builder')),
  UnwrapTheGiftBuilder: lazy(() => import('@/games/UnwrapTheGift/builder')),
  SimplySpinBuilder: lazy(() => import('@/games/SimplySpin/builder')),
  PeelToRevealBuilder: lazy(() => import('@/games/PeelToReveal/builder')),
  ClickToRevealBuilder: lazy(() => import('@/games/ClickToReveal/builder')),
  WheelOfFortuneBuilder: lazy(() => import('@/games/WheelOfFortune/builder')),
  AdventCalendarBuilder: lazy(() => import('@/games/AdventCalendar/builder')),
  ShootHoopsBuilder: lazy(() => import('@/games/ShootHoops/builder')),
  KickNScoreBuilder: lazy(() => import('@/games/KickNScore/builder')),
  ScoreAGoalBuilder: lazy(() => import('@/games/ScoreAGoal/builder')),
  RollTheDiceBuilder: lazy(() => import('@/games/RollTheDice/builder')),
  AdventCalendarLottieBuilder: lazy(
    () => import('@/games/AdventCalendarLottie/builder')
  ),
};

export default function BlockGames({
  template,
  presets,
  segments,
  goNextStep,
  currentStep,
  setStoredValue,
}: BlockGamesProps) {
  const defaultValues = useMemo(
    () => ({
      blockPresets: presets,
      currentStep: currentStep,
      segments,
    }),
    [presets, currentStep]
  );

  switch (template) {
    case 'wheel-of-fortune':
      return (
        <Components.WheelOfFortuneBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'grabber':
      return (
        <Components.GrabberBuilder {...defaultValues} nextStep={goNextStep} />
      );
    case 'slot-machine':
      return <Components.SlotMachineBuilder {...defaultValues} />;
    case 'scratch-off':
      return <Components.ScratchCard3Builder {...defaultValues} />;
    case 'rotating-envelopes-v2':
      return (
        <Components.MysteryEnvelopeBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
          storeAux={setStoredValue}
        />
      );
    case 'what-is-in-the-box':
      return (
        <Components.MysteryBoxBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'pop-the-balloon':
      return (
        <Components.PopTheBalloonBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'simply-pop':
      return (
        <Components.SimplyPopBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'unwrap-the-gift':
      return (
        <Components.UnwrapTheGiftBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'open-the-envelope':
      return (
        <Components.OpenTheEnvelopeBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'simply-spin':
      return (
        <Components.SimplySpinBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'click-to-reveal':
      return (
        <Components.ClickToRevealBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'peel-to-reveal-lottie':
      return (
        <Components.PeelToRevealBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'shoot-hoops':
      return (
        <Components.ShootHoopsBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'kick-n-score':
      return (
        <Components.KickNScoreBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'score-a-goal':
      return (
        <Components.ScoreAGoalBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'scratcher':
      return (
        <Components.Scratcher
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'scratch-and-match':
      return (
        <Components.ScratchAndMatch
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'simply-scratcher':
      return (
        <Components.SimplyScratcher
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'advent-calendar-20':
      return (
        <Components.AdventCalendarBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    case 'advent-calendar-30':
      return (
        <Components.AdventCalendarLottieBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );

    case 'roll-the-dice':
      return (
        <Components.RollTheDiceBuilder
          {...defaultValues}
          nextStep={goNextStep}
          blockId={`blkd-section-${currentStep}-block-${template}`}
        />
      );
    default:
      return <></>;
  }
}
